export default {
    computed: {
        showFullEditLink() {
            switch(this._data._class) {
                case 'Mail':
                case 'MailTemplateBlock':
                case 'BaseCourseAction':
                case 'Mailtemplate':
                case 'Holiday':
                case 'Renter':
                case 'Reservation':
                case 'Room':
                    return false;
                    break;
                default:
                    return true;
                    break;
            }
        },
    },
    methods: {

        calculateEndTime() {
            return 'oke'
        },
        
        getEditSubTitle() {
            
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'Holiday':              entityName = ''; break;
                case 'Room':                 entityName = 'Ruimte'; break;
                case 'Reservation':          entityName = ''; break;
                case 'Recurringevent':       entityName = ''; break;
                case 'Renter':               entityName = ''; break;
                case 'Invoice':              entityName = 'Factuur'; break;
                
            }
            return entityName;
            
        },
        getEditTitle() {
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'Holiday':             entityName = 'Vakantie'; break;
                case 'Room':                entityName = 'Ruimte'; break;
                case 'Reservation':         entityName = 'Reservering'; break;
                case 'Recurringevent':      entityName = 'Vaste reservering'; break;
                case 'Renter':              entityName = 'Gebruiker'; break;
                
            }
            return entityName;
            
        },
        getEditName(data) {
            if (!data.id) return '';
            var html = [];

            
            switch(data._class) {
                case 'BaseCourse':
                case 'Course':
                case 'CourseLocation':
                case 'Customer':    
                    html.push(data.name+' - ID: '+data.id);
                    break;
                case 'Participant':    
                    html.push(data.fullname+'  ('+data.Customer.name+')');
                    break;
                case 'User':    
                    html.push(data.fullname+' - ID: '+data.id);
                    break;
                case 'MailTemplate':    
                    html.push((data.BaseCourse.acronym?data.BaseCourse.acronym:'Default')+(data.type?' - '+data.type+' template':''));
                    break;
                case 'Enrollment':    
                    html.push(data.Participant.fullname+'  ('+data.Customer.name+')');
                    break;
                case 'BaseCourseAction':    
                    html.push(data.BaseCourse.name);
                    break;
                case 'Room':    
                case 'Reservation':    
                case 'Renter':    
                case 'Holiday':    
                    html.push(data.name);
                    break;
            }
            return html.join('<br />');
        },

        leadingZero(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
        },

        getEndDateTime(date, time, hours) {
            //console.log(date+' '+time, hours)

            // let date = '2023-10-23';
            // let time = '23:00';
            // let hours = 1.25;

            // let e = date.split('-');
            // let start = new Date();
            // start.setUTCFullYear(e[0]);
            // start.setMonth((parseInt(e[1])-1));
            // start.setDate(parseInt(e[2]));

            // let e2 = time.split(':');
            // start.setHours(parseInt(e2[0]));
            // start.setMinutes(parseInt(e2[1]));
            // start.setSeconds(10);
            // console.log('start:', e, (parseInt(e[1])-1), e2, start, start.toLocaleString())

            let timestamp = date+' '+time+':00';
            // console.log('timestamp:', timestamp)
            var t = timestamp.split(/[- :]/);

            // Apply each element to the Date function
            let start = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
            //console.log('start:', start.toLocaleString())

            let timezoneDiff = -start.getTimezoneOffset()/60;
            //let timezoneDiff = 0;
            
            let end = new Date(start.getTime() + hours*60*60*1000 - timezoneDiff*60*60*1000);
            //console.log('end:', end.toLocaleString())
            let dateSplit = end.toLocaleString().split(' ');
            
            
            let e3 = dateSplit[0].split('-');
            //console.log(e3, this.leadingZero(e3[0],2))
            let enddate = e3[2].trim()+'-'+this.leadingZero(e3[1],2)+'-'+this.leadingZero(e3[0],2);
            let res = enddate+' '+this.leadingZero(dateSplit[1],5);
            res = res.replace(',-','-')
            console.log(res)
            return res;

        },
        getEndtime(date, time, hours) {
            //debugger
            //console.log(item)
            // if (!date) {
            //     date = item.startdate.substr(0,10);
            // }

            date = date.substr(0,10);

            const dateTime = date+' '+time+':00';
            let duration = hours;
            let addHours = Math.floor(duration);
            let addMinutes = Math.round((duration-addHours)*60);
            addMinutes+=addHours*60;
            
        
            let dateTimeParts= dateTime.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
            dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one
        
            const dateObject = new Date(...dateTimeParts);
        
            dateObject.setMinutes(dateObject.getMinutes() + addMinutes);
        
            //var newDateObj = moment(dateObject).add(addMinutes, 'm').toDate();
            return dateObject.toTimeString().substr(0,5);
        },

        getDayname(day) {
            switch(day) {
              case 0:
                return 'Zondag';
              case 1:
                return 'Maandag';
              case 2:
                return 'Dinsdag';
              case 3:
                return 'Woensdag';
              case 4:
                return 'Donderdag';
              case 5:
                return 'Vrijdag';
              case 6:
                return 'Zaterdag';
              case 7:
                return 'Zondag';
            }
        },
    }
}
