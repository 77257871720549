import { createStore } from 'vuex'

export default createStore({
  state: {
    loaderstate: false,
    login: {},
    lastlogin: false,
    settings: [],
    rooms: null,
    alertcounts: null,
    mailtemplates: null,
    dashboardusers: []
  },
  getters: {
    get_loaderstate: state => { return state.loaderstate; },
    get_login: state => { return state.login; },
    get_lastlogin: state => { return state.lastlogin; },
    get_settings: state => { return state.settings; },
    get_rooms: state => { return state.rooms; },
    get_alertcounts: state => { return state.alertcounts; },
    get_mailtemplates: state => { return state.mailtemplates; },
    get_dashboardusers: state => { return state.dashboardusers; }
  },
  mutations: {
    SET_LOADERSTATE(state, val) { state.loaderstate = val; },
    SET_LOGIN(state, val) { state.login = val; },
    SET_LASTLOGIN(state, val) { state.lastlogin = val; },
    SET_SETTINGS(state, val) { state.settings = val; },
    SET_ROOMS(state, val) { state.rooms = val; },
    SET_ALERTCOUNTS(state, val) { state.alertcounts = val; },
    SET_MAILTEMPLATES(state, val) { state.mailtemplates = val; },
    SET_DASHBOARDUSERS(state, val) { state.dashboardusers = val; }
  },
  actions: {
    set_loaderstate({ commit }, val) { commit('SET_LOADERSTATE', val); },
    set_login({ commit }, val) { commit('SET_LOGIN', val); },
    set_lastlogin({ commit }, val) { commit('SET_LASTLOGIN', val); },
    set_settings({ commit }, val) { commit('SET_SETTINGS', val); },
    set_rooms({ commit }, val) { commit('SET_ROOMS', val); },
    set_alertcounts({ commit }, val) { commit('SET_ALERTCOUNTS', val); },
    set_mailtemplates({ commit }, val) { commit('SET_MAILTEMPLATES', val); },
    set_dashboardusers({ commit }, val) { commit('SET_DASHBOARDUSERS', val); }
  }
})