import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import HomeView from '../pages/custom/Home.vue'
import AboutView from '../views/AboutView.vue'
import TodosView from '../views/TodosView.vue'
//import JobsView from '../views/Jobs'
//import CoursesView from '../views/Courses'
import LoginView from '../pages/base/Login'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login',
    name: 'login',
    sectionTitle:'Login',
    loggedOut: true,
    component: LoginView
  },
  
  { path: '/profile', name:'profile', title: 'Profiel', component: () => import('../pages/base/User/Profile') },
  { path: '/profile/password', name:'profile_password', title: 'Wachtwoord', component: () => import('../pages/base/User/Password') },


  

  //{ path: '/dashboard', sectionTitle:'Dashboard', icon:'fa-gauge', title:'Overzicht', name: 'dashboard_overview', component: () => import('../pages/custom/Dashboard/Overview')},
  
  // CUSTOMERS

  // DASHBOARD
  

  // CALENDAR
  { path: '/reservations', sectionTitle: 'Reserveren', icon:'fa-calendar-days', name:'reservations_open',  title:"Nieuw", component: () => import('../pages/custom/Reservation/List'), meta:{type:'open'} },
  { path: '/reservations/future', name:'reservations_future',  title:"Goedgekeurd", component: () => import('../pages/custom/Reservation/List'), meta:{type:'future'} },
  { path: '/reservations/cancelled', name:'reservations_cancelled',  title:"Geweigerd", component: () => import('../pages/custom/Reservation/List'), meta:{type:'cancelled'} },
  { path: '/reservations/calendar', name:'reservations_calendar',  title:"Kalender", component: () => import('../pages/custom/Calendar/Overview') },
  { path: '/reservations/recurring', name:'reservations_recurring',  title:"Terugkerend", component: () => import('../pages/custom/Recurringevent/List') },


// RUIMTES
{ path: '/rooms', sectionTitle:'Ruimtes', icon:'fa-kaaba',  title:'Overzicht', name: 'rooms_overview', component: () => import('../pages/custom/Room/List') },

// GEBRUIKERS / HUURDERS
{ path: '/renters', sectionTitle:'Gebruikers', icon:'fa-id-card',  title:'Overzicht', name: 'renters_overview', component: () => import('../pages/custom/Renter/List') },

// VAKANTIES
{ path: '/holidays', sectionTitle:'Vakanties', icon:'fa-umbrella-beach',  title:'Overzicht', name: 'holidays_overview', component: () => import('../pages/custom/Holiday/List') },

// RESERVERINGEN
//{ path: '/reservations', sectionTitle:'Reserveringen', icon:'fa-kaaba',  title:'Overzicht', name: 'rooms_overview', component: () => import('../pages/custom/Room/List') },
// { path: '/projects/activities', title:'Activiteiten', name: 'projects_activities', component: () => import('../pages/custom/Project/Activities') },
// { path: '/projects/tags', title:'Tags', name: 'projects_tags', component: () => import('../pages/custom/Project/Tags') },
// { path: '/projects/edit/:id', name: 'projects_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Project bewerken', controller:'projects', afterSave:{all:'/projects'}} },
// { path: '/projects/details/:id', name: 'projects_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Project details', controller:'projects'} },


// KLANTEN
// { path: '/customers', sectionTitle:'Klanten', icon:'fa-people-group',  title:'Overzicht', name: 'customers_overview', component: () => import('../pages/custom/Customer/List') },
// { path: '/customers/branches', title:'Branches', name: 'customers_branches', component: () => import('../pages/custom/Customer/Branches') },
// { path: '/customers/stripcards', title:'Strippenkaarten', name: 'customers_stripcards', component: () => import('../pages/custom/Customer/Stripcards') },
// // { path: '/customers/edit/:id', name: 'customer_edit', component: () => import('../pages/custom/Customer/Edit') },
// // { path: '/customers/new', name: 'customer_add', component: () => import('../pages/custom/Customer/Edit') },
// { path: '/customers/edit/:id', name: 'customers_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant bewerken', controller:'customers', afterSave:{all:'/customers'}} },
// { path: '/customers/details/:id', name: 'customers_details', component: () => import('../pages/custom/Customer/Details'), meta:{title:'Klant details', controller:'customers'} },
// { path: '/customers/new', name: 'customers_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant toevoegen', controller:'customers', afterSave:{all:'/customers'}} },
// { path: '/customers/:id/newcontactperson', name: 'customers_addcontactperson', component: () => import('../pages/base/_Entity/AddRelation'), meta:{title:'Contactpersoon toevoegen', controller:'customers', relationController:'contactpersons', entity:'Contactperson', sourceIdField:'cus_id', afterSave:{all:'/customers/details/:id'}} },


// FACTUREN
// { path: '/invoices', sectionTitle:'Facturen', icon:'fa-euro', title:'Overzicht', name: 'invoices_overview', component: () => import('../pages/custom/Invoice/List'), meta:{type:'open'} },
// { path: '/invoices/openrules', title:'Te factureren', name: 'invoices_openrules', component: () => import('../pages/custom/Invoice/OpenRules') },
// { path: '/invoices/overdue', title:'Vervallen', name: 'invoices_overdue', component: () => import('../pages/custom/Invoice/List'), meta:{type:'overdue'} },
// { path: '/invoices/unpaid', title:'Open', name: 'invoices_unpaid', component: () => import('../pages/custom/Invoice/List'), meta:{type:'unpaid'} },
// { path: '/invoices/paid', title:'Betaald', name: 'invoices_paid', component: () => import('../pages/custom/Invoice/List'), meta:{type:'paid'} },
// { path: '/invoices/new/:cus_id', name: 'invoices_new', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur toevoegen', controller:'invoices'} },
// { path: '/invoices/new/:cus_id/:pro_id', name: 'invoices_new_project', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur toevoegen', controller:'invoices'} },
// { path: '/invoices/edit/:id', name: 'invoices_edit', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur bewerken', controller:'invoices'} },

  // TOOLS
  { path: '/tools', icon:'fa-gear',sectionTitle:'Tools', name:'tools_mails', title: 'Mails', component: () => import('../pages/base/Tools/Mails') },
  { path: '/tools/log', name:'tools_lot', title: 'Log', component: () => import('../pages/base/Tools/Log') },
  { path: '/tools/permissions',   name:'tools_permissions', title:"Permissions", component: () => import('../pages/base/Tools/Permissions') },
  { path: '/tools/database', name:'tools_database', title: 'Database', component: () => import('../pages/base/Tools/Database') },
  { path: '/tools/migrate', name:'tools_migrate', title: 'Migrate', component: () => import('../pages/custom/Tools/Migrate') },
  { path: '/tools/cache', name:'tools_cache', title: 'Cache', component: () => import('../pages/base/Tools/Cache') },
  { path: '/tools/test', name:'tools_test', title: 'Test', component: () => import('../pages/custom/Tools/Test') },

// MEDEWERKERS
{ path: '/users', sectionTitle:'Beheerders', icon:'fa-person-chalkboard', title:'Overzicht',name: 'users_overview', component: () => import('../pages/custom/User/List') },
// { path: '/users/edit/:id', name: 'user_edit', component: () => import('../pages/base/User/Edit') },
// { path: '/users/new', name: 'user_add', component: () => import('../pages/base/User/Edit') },
{ path: '/users/details/:id', name: 'users_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Gebruiker details', controller:'users'} },
{ path: '/users/edit/:id', name: 'users_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker bewerken', controller:'users', afterSave:{all:'/users'}} },
{ path: '/users/new',  name: 'users_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker toevoegen', controller:'users', afterSave:{all:'/users'}} },

// roles
{ path: '/users/roles', sectionTitle:'Rollen', title:'Rollen', name: 'roles_overview', component: () => import('../pages/base/Role/List') },
{ path: '/roles/edit/:id', name: 'roles_edit', component: () => import('../pages/base/Role/Edit') },
{ path: '/roles/new', title:'Toevoegen', name: 'roles_add', component: () => import('../pages/base/Role/Edit') },


// LIBRARY
//{ path: '/library', sectionTitle: 'Bibliotheek', icon:'fa-photo-film', className:'hasFileTree', name:'library', title:"Overzicht", component: () => import('../pages/custom/Library/Overview') },

// SETTINGS
{ path: '/settings', sectionTitle: 'Instellingen', icon:'fa-gears', name:'settings', title:"Variabelen", component: () => import('../pages/base/Settings/Overview') },
{ path: '/settings/mailstatus', name:'settings_mailstatus', title:"Mail status", component: () => import('../pages/base/Settings/Mailstatus') },
{ path: '/settings/mailtemplates', name:'settings_mailtemplates', title:"Mail templates", component: () => import('../pages/base/Settings/Mailtemplates') },
{ path: '/settings/msgraph', name:'settings_msgraph', title:"Microsoft", component: () => import('../pages/base/Settings/MSGraph') },


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_COMPANY_NAME;
  next();
});

export default router
